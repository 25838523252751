import React, { useEffect } from "react";
import "./Header.css";
import logo from "./assets/img/logo poziom białe.png";
import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import { Link, useNavigate} from "react-router-dom";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import cogoToast from "cogo-toast"

const Header = ({pages}) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate()
  const onMenu = () => {
    if (menu === false) {
      setMenu(true);
    } else {
      setMenu(false);
    }
  };

  const onLogout = () => {
    sessionStorage.clear();
    cogoToast.success("Użytkownik wylogowany");
    setTimeout(() => {
      window.location.href = "/";
    }, [1500]);
  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo_link">
          <img src={logo} className="logo" alt="logo"></img>
        </Link>
        <i onClick={onMenu} className="fa fa-bars"></i>
        <ul style={{fontSize:"10px"}} className={menu === true ? "header-nav-visible" : "header-nav"}>
          { user && user?.user.type === "admin" && 
          <div className="menu-box">
              <div style={{display:"flex", flexFlow:"row", alignItems:"flex-start", gap:"2px"}}>
              {pages.map((page,index) => {
                if (!page.routes) {
                  return null;
                }
                const pageIndexes = page.ids
                const pdfs = page.pdfs
                const pageNames = page.names
                return (
                  <React.Fragment key={page.id}>
                    <Menu menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>{page.title}</MenuButton>}>
                    {page.routes
                    .map((route, innerIndex) => (
                      <MenuItem 
                      key={pageIndexes[innerIndex]} 
                      onClick={() => {
                        navigate(`/${pageIndexes[innerIndex]}/${pdfs[innerIndex]}/${[page.id]}`)}} className="menu-list">{pageNames[innerIndex]}</MenuItem>
                    ))}
                    </Menu>
                  </React.Fragment>
                )
              })}
              </div>
              {/* <MenuItem className="menu-list">Omówienie CRMa</MenuItem>
              <MenuItem className="menu-list">Osoby do kontaktu</MenuItem>
              <MenuItem className="menu-list">Jak dodać klienta</MenuItem>
              <MenuItem className="menu-list">Omówienie Kalkulatorów</MenuItem>
              <MenuItem className="menu-list">Generowanie oferty</MenuItem>
              <MenuItem className="menu-list">Generowanie umowy <br></br>+ podpis</MenuItem>
              <MenuItem className="menu-list">Uzupełnianie audytów</MenuItem>
              <MenuItem className="menu-list">Weryfikacja umowy</MenuItem>
              <MenuItem className="menu-list">Dzwoneczki i zadania</MenuItem>
              <MenuItem className="menu-list">Wypłata prowizji</MenuItem> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Dofinansowania</MenuButton>}>
              <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"10px"}}>
              <MenuItem className="menu-list">Mój prąd</MenuItem>
              <MenuItem className="menu-list">Czyste powietrze</MenuItem>
              </div>
            </Menu> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Szkolenia techniczne</MenuButton>}>
              <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
                <MenuItem className="menu-list">Jak działa fotowoltaika</MenuItem>
                <MenuItem className="menu-list">Jak działa pompa ciepła</MenuItem>
                <MenuItem className="menu-list">Jak działa piec pelletowy </MenuItem>
                <MenuItem className="menu-list">Jak działa rekuperacja</MenuItem>
                <MenuItem className="menu-list">Jak działa klimatyzacja </MenuItem>
                <MenuItem className="menu-list">Jak działa hydraulika</MenuItem>
                <MenuItem className="menu-list">Technologia budowy <br></br> naszych okien i drzwi</MenuItem>
              </div>
            </Menu> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Pozyskiwanie klienta</MenuButton>}>
              <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
                <MenuItem className="menu-list">Metoda door to door </MenuItem>
              </div>
            </Menu> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Skrypty sprzedażowe</MenuButton>}>
              <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
              <MenuItem className="menu-list">Skrypt fotowoltaika </MenuItem>
              <MenuItem className="menu-list">Skrypt pompa ciepła </MenuItem>
              <MenuItem className="menu-list">Skrypt piec </MenuItem>
              </div>
            </Menu> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Zamykanie sprzedaży</MenuButton>}>
              <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
                <MenuItem className="menu-list">Główne metody zamykania </MenuItem>
                <MenuItem className="menu-list">Szukanie prawdziwej obiekcji </MenuItem>
                <MenuItem className="menu-list">Neutralizacja obiekcji </MenuItem>
                <MenuItem className="menu-list">Telefon do dyrektora </MenuItem>
                <MenuItem className="menu-list">Rozładowanie emocji </MenuItem>
              </div>
            </Menu> */}
            {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Obsługa posprzedażowa</MenuButton>}>
          <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
          <MenuItem className="menu-list">ABC obsługi posprzedażowej </MenuItem>
          </div>
    </Menu> */}
    {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Zbieranie poleceń</MenuButton>}>
          <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
          <MenuItem className="menu-list">ABC zbierania poleceń </MenuItem>
          </div>
    </Menu> */}
    {/* <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Zbieranie poleceń</MenuButton>}>
          <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
          <MenuItem className="menu-list">ABC Rekrutacji</MenuItem>
          </div>
    </Menu> */}
          </div>}
          {/* <li>
            <Link className="header-link-lite" to="/">
              Strona główna
            </Link>
          </li> */}
          { user && user?.user.type === "admin" && <Menu menuDirection={"left"} menuButton={<MenuButton style={{border:"none", fontFamily:"Poppins", backgroundColor:"transparent", color:"white"}}>Panel Admina</MenuButton>}>
            <div style={{display:"flex", flexFlow:"column", alignItems:"flex-start", gap:"2px"}}>
            <MenuItem onClick={() => {
              navigate("/addPresentation")
            }} className="menu-list">
             <li>
               Dodaj Prezentacje
          </li>
          </MenuItem>
           <MenuItem onClick={() => {
              navigate("/addAccount")
            }} className="menu-list">
             <li>
               Dodaj Użytkownika
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/users")
            }} className="menu-list">
             <li>
               Wszyscy użytkownicy
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/editQuestions")
            }} className="menu-list">
             <li>
               Edycja Pytania do Quizu
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/choosePage")
            }} className="menu-list">
             <li>
               Edycja Podstrony
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/allPages")
            }} className="menu-list">
             <li>
               Wszystkie strony
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/addQuiz")
            }} className="menu-list">
             <li>
               Dodaj Quiz
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/changePassword")
            }} className="menu-list">
             <li>
               Zmień hasło
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/addPage")
            }} className="menu-list">
             <li>
               Dodaj nową stronę
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/addSubPages")
            }} className="menu-list">
             <li>
               Dodaj podstronę
          </li>
          </MenuItem>
          <MenuItem onClick={() => {
              navigate("/addCheat")
            }} className="menu-list">
             <li>
               Dodaj Ściągi
          </li>
          </MenuItem>
            </div>
          </Menu>}
          {!user ? (
            <>
              <div>
                <Link to="/login">
                  <button style={{fontSize:user?.user.type === "admin" ? "10px": "16px"}} className="login-button">Zaloguj się</button>
                </Link>
              </div>
            </>
          ) : null}
        </ul>
        <div className="header-button-box">
          {user !== null && (
            <div>
                <button onClick={onLogout} style={{backgroundColor:"red", border:"none", width:"110px", height:"30px"}} className="header-button-logout">Wyloguj</button>
            </div> 
          )}
        </div>
      </div>
    </header>
  );
};
export default Header;
