import { useEffect, useRef, useState } from "react"
import { useParams,useSearchParams } from "react-router-dom"
import { BACKEND_URL } from "../constants"
import { pdfjs } from 'react-pdf';
import ClipLoader from "react-spinners/ClipLoader";
import ReactPlayer from 'react-player'
import cogoToast from "cogo-toast"
import { Document, Page } from 'react-pdf';
import PagingControl from "./PaggingControl"

const Template = ( ) => {
    const params = useParams()
    const initialized = useRef(false);
    console.log("params", params)
    const [numPages, setNumPages] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const pageDetailsRef = useRef(null);
    const [pages, setpages] = useState([]);
    const [loading,setLoading] = useState(false)
    const [searchParams] = useSearchParams()

    console.log(searchParams.get('link'))

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTimeout(() => {
          setNumPages(numPages);
        },[1000])
      }

      const handlePageNum = (pageNum) => {
        setPageNum(pageNum);
      }

    useEffect(() => {
          setLoading(true)
          setTimeout(() => {
            fetch(`${BACKEND_URL}/allSubPages`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": true,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                  setpages(response);
                  setLoading(false)
                  console.log(response, "resuu")
              })
              .catch((error) => {
                console.error("error: ", error);
              })
              .finally(() => setLoading(false));
          }, [1000]);
      }, []);

      console.log(pages, "pages")

    return (
        <div className="template">
          {loading === true && <div style={{marginTop:'40px'}}><ClipLoader color="black" size={50} aria-label="Loading Spinner" data-testid="loader"/></div>} 
              {pages
              .filter((page) => page.page_id == params.page_id && page.id == params.id)
              .map((page) => {
                console.log(page, "page")
                  return(
                    <div key={params.id} style={{paddingTop:"40px", paddingBottom:"40px", display:"flex", gap:"5px", alignItems:"center", flexFlow:'column'}}>
                      <h2 style={{color:"white", fontFamily:"poppins", fontSize:"40px"}}>{page.name}</h2>
                      <p style={{maxWidth:'400px', color:"white", fontFamily:"poppins"}}>{page.description}</p>
                      <ReactPlayer url={page.link} />
                      {searchParams.get('link') == "undefined" ? null :
                  <div style={{paddingBottom:"20px"}}>
                        <Document file={searchParams.get('link')} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page
                        pageNumber={pageNum}
                        width={644}
                        height={450} 
                        onLoadSuccess={(data) => {
                          pageDetailsRef.current = data;
                        }}
                      />
                      </Document>
                      <PagingControl
                          pageNum={pageNum}
                          onPageChange={handlePageNum}
                          totalPages={numPages}
                          />
                        <a style={{color:"orange", fontSize:"27px", marginBottom:"30px",cursor:"pointer"}} href={page?.pdf}>Pobierz pdf</a>
                  </div>}
                    </div>
                  )
              })}
              </div>
    )}
export default Template;