import './App.css';
import Header from "./Header"
import Footer from './Footer';
import { Routes, Route } from "react-router-dom";
import Home from './Home'
import PageLogin from './components/Login'
import Register from './components/Register'
import Presentation from './components/AddPresentation';
import PdfViewer from './components/PdfViewer'
import Quiz from './components/Quiz'
import AddQuiz from './components/AddQuiz'
import EditQuestions from './components/EditQuestions'
import UserProfile from './components/UserProfile'
import AddAccount from './components/AddAccount'
import AllUsers from './components/AllUsers'
import AddCheats from './components/AddCheats'
import AddPage from './components/AddPage'
import AddSubPages from './components/AddSubPages'
import { useEffect, useRef, useState } from 'react'
import { BACKEND_URL } from './constants'
import Template from './components/Template'
import EditPages from './components/EditPages'
import ChoosePage from './components/ChoosePage'
import ForgotPasword from './ForgotPassword'
import ChangePasword from './ChangePassword'
import AllPages from './components/AllPages'

function App() {
  const [pages, setPages]= useState([])
  const initialized = useRef(false);
  const [loading, setLoading]= useState(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setLoading(true);
      setTimeout(() => {
        fetch(`${BACKEND_URL}/subPages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.hasOwnProperty("error")) {
              setPages(response);
            }
          })
          .catch((error) => {
            console.error("error: ", error);
          })
          .finally(() => setLoading(false));
      }, [1000]);
    }
  }, [pages]);
  return (
    <div className="App">
      <Header pages={pages}/>
      <div className="main">
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<PageLogin />} />
          <Route exact path="/forgotPassword" element={<ForgotPasword />} />
          <Route exact path="/changePassword" element={<ChangePasword />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/addPresentation" element={<Presentation />} />
          <Route exact path="/addCheat" element={<AddCheats />} />
          <Route exact path="/allPages" element={<AllPages />} />
          <Route exact path="/addAccount" element={<AddAccount />} />
          <Route exact path="/pdf" element={<PdfViewer />} />
          <Route exact path="/quiz" element={<Quiz/>} />
          <Route exact path="/addQuiz" element={<AddQuiz/>} />
          <Route exact path="/addPage" element={<AddPage/>} />
          <Route exact path="/addSubPages" element={<AddSubPages/>} />
          <Route exact path="/editQuestions" element={<EditQuestions/>} />
          <Route exact path="/editSubPages/:id/:page_id" element={<EditPages/>} />
          <Route exact path="/choosePage" element={<ChoosePage/>} />
        <Route exact path="/profile" element={<UserProfile/>} />
          <Route exact path="/users" element={<AllUsers/>} />
          {pages.map((page) => {
            return page.routes && page.routes.map((route, index) => {
              const data = { id: page.ids[index], route, description: page.descriptions[index], link: page.links[index], name: page.names[index], title: page.title, pdf: page.pdfs[index] }
              return <Route path={`/:id/:pdf/:page_id`} element={<Template page={data} />} />
            })
            })}
        </Routes>
      </div>
    </div>
  );
}

export default App;
