import cogoToast from "cogo-toast"
import React, { useEffect, useRef, useState } from "react";
// import { DEMO_POSTS_NEWS } from "data/posts";
import { BACKEND_URL } from "../constants"
//

const AllPages = () => {
  const [data,setData] = useState([])
  const [number, setNumber]= useState(0)
  const [subPages,setSubPages] = useState([])
  const [loading, setLoading]= useState(false)
  const [pageId, setPageId] = useState(1)
  let user = sessionStorage.getItem("user")
  const initialized = useRef(false);

  const onChange = (e) => {
    setNumber(e.target.value);
};

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetch(`${BACKEND_URL}/pages`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.hasOwnProperty("error")) {
           setData(response)
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
    }
  }, [JSON.parse(user)?.user.id, user]);


  useEffect(() => {
      fetch(`${BACKEND_URL}/allSubPages`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
           setSubPages(response)
           console.log(response, "resp")
        })
        .catch((error) => {
          console.error("error: ", error);
        })
        .finally(() => {
          console.log("finally")
        })
  }, [JSON.parse(user)?.user.id, user]);

  const changeOrderNumber = async (id) => {
    try{
      const response = await fetch(`${BACKEND_URL}/changeNumber`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        number: number,
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Kolejność występowania sliderów została zaauktualizowana"
      );
     window.location.reload()
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}

  const onDelete = (id) => {
    setTimeout(() => {
      fetch(`${BACKEND_URL}/delete-banner`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Banner został usunięty");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  }


  const deleteSlider = (id) => {
    setTimeout(() => {
      fetch(`${BACKEND_URL}/delete-slider`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Grupa Bannerowa została usunięta");
            window.location.reload()
          } 
        })
        .catch((error) => console.error("error: ", error));
    }, [2000]);
  }


  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <div className="relative overflow-hidden">
        <div className="container relative">
        <h1 style={{marginTop:"50px",marginBottom:"50px"}} className="text-2xl md:text-3xl lg:text-4xl font-semibold">Wszystkie Strony</h1>
       <div style={{display:"flex"}} className="option-box">
        {data
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((slider, innerIndex) => {
          return(
            <div style={{display:'flex', flexFlow:"column", justifyContent:'space-between', alignItems:'center'}}>
            <button style={{padding:"20px", fontSize:"16px", backgroundColor:'transparent', color:slider.page_id == pageId ? "red": "black",  border:"none"}} onClick={(e) => setPageId(e.target.value)} key={slider.id} value={slider.id}>{slider.title}</button>
            {/* <div style={{marginBottom:'10px', display:"flex", flexFlow:"column"}}>
              <span style={{fontSize:"20px"}}>Kolejnosć numeracji: {slider.orderNumber}</span>
              <div style={{display:'flex', flexFlow:"column", justifyContent:"center", alignItems:'center', gap:"10px"}}>
              <input type="number" style={{width:'27%', margin:"0 auto"}} onChange={onChange} name="number" className="form-control"/>
              <button className="btn btn-primary" onClick={() => changeOrderNumber(slider.id)}>Zauktualizuj kolejność</button>
              </div>
            </div> */}
            {/* <button onClick={() => deleteSlider(slider?.id)} style={{backgroundColor:'red', width:"88%", padding:"8px", fontSize:'13px', border:"none", color:"white"}}>Usuń stronę</button> */}
            </div>
          )
        })}
       </div>
       <div style={{paddingTop:"50px", display:'grid', paddingBottom:"50px",  gap:"30px",grid:'auto / auto auto'}} className="project-box">
       {subPages
       .filter((page) => page.page_id == pageId )
       .sort((a,b) => a.orderNumber - b.orderNumber)
       .map((page) => {
          return(
            <div key={page.id} style={{marginBottom:'10px', display:"flex", flexFlow:"column"}}>
            <span style={{fontSize:"20px"}}>Kolejnosć numeracji: {page.orderNumber}</span>
            <h3>{page?.name}</h3>
            <div style={{display:'flex', flexFlow:"column", justifyContent:"center", alignItems:'center', gap:"10px"}}>
            <input type="number" style={{width:'27%', margin:"0 auto"}} onChange={onChange} name="number" className="form-control"/>
            <button className="btn btn-primary" onClick={() => changeOrderNumber(page.id)}>Zauktualizuj kolejność</button>
            </div>
          </div>
          )
        })}
       </div>
        </div> 
      </div>
    </div>
  );
};

export default AllPages;
