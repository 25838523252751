import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"
import Files from 'react-files'
import Footer from "../Footer";
import PublitioAPI from 'publitio_js_sdk'
import axios from "axios";
import { useForm } from 'react-hook-form';


const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')

const AddSubPages = () => {
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()
  const [pdf,setPdf]= useState("")
  const initialized = useRef(false);
  const [pages,setPages]= useState([])

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
      route:"",
      link:"",
      page_id:null,
    },
  });


const handleError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message)
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setLoading(true);
      setTimeout(() => {
        fetch(`${BACKEND_URL}/pages`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (!response.hasOwnProperty("error")) {
              setPages(response);
            }
          })
          .catch((error) => {
            console.error("error: ", error);
          })
          .finally(() => setLoading(false));
      }, [1000]);
    }
  }, [pages]);

  const handleChange = (files) => {
    setPdf(files[0])
    console.log(files[0], "files")
  }

  const addSubPage = async (values,e) => {
    e.preventDefault()
    let data;
    try{
      data = await publitio.uploadFile(pdf, 'file')
      console.log("data", data)
      const response = await fetch(`${BACKEND_URL}/subPages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: values.name,
        description: values.description,
        pdf: data.url_preview,
        link: values.link,
        page_id:values.page_id
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Podstrona została dodana pomyślnie"
      );
      window.location.reload()
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Dodaj nową podstronę</p>
          </div>
          <form onSubmit={handleSubmit(addSubPage)}>
          <div className="login-user-placeholder">
            <input
             {...register("name", {
              required: { value: true, message: "Pole wymagane" },
            })}
              style={{padding:"10px"}}
              name="name"
              type="text"
              placeholder="Dodaj tytuł podstrony"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <textarea
             {...register("description", {
              required: { value: true, message: "Pole wymagane" },
            })}
              style={{padding:"10px"}}
              name="description"
              placeholder="Dodaj opis na podstronie"
              className="login-user-line"
            />
          </div>
          <div className="login-user-placeholder">
            <input
             {...register("link", {
              required: { value: true, message: "Pole wymagane" },
            })}
              style={{padding:"10px"}}
              name="link"
              type="text"
              placeholder="Dodaj link do yt"
              className="login-user-line"
            />
          </div>
            <div className="files">
      <Files
      style={{cursor:"pointer"}}
        className='files-dropzone'
        onChange={handleChange}
        onError={handleError}
        accepts={['image/png', '.pdf', 'audio/*']}
        multiple
        maxFileSize={10000000}
        minFileSize={0}
        clickable>
          Plik w pdfie
      </Files>
      <span>{pdf?.name}</span>
    </div>
    <div className="login-user-placeholder">
           <select required
                  {...register("page_id", {
                    required: { value: true, message: "Pole wymagane" },
                  })}>
                    <option value="Wybierz podstronę">Wybierz stronę</option>
              {pages.map((option) => {
                return <option key={option.id} id={option.id} value={option.id}>{option.title}</option>
                 })}
            </select>
          </div>
          <input type="submit" value="Stwórz nową podstronę" className="login-user-button"/>
    </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default AddSubPages;
