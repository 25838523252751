import { useState } from "react";
import cogoToast from "cogo-toast";
import { Link, useNavigate } from 'react-router-dom';
import { BACKEND_URL } from "../constants"
import Footer from "../Footer"

const LoginUser = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const navigate = useNavigate()

  const onChange = (e) => {
    if (e.target.name === "login") {
      setEmail(e.target.value);
    } else {
      setPass(e.target.value);
    }
  };

//   const ForgotPassword = () => {
//     fetch(
//       `${process.env.REACT_APP_API}/api/Account/ForgotPassword?Email=${email}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//       .then((res) => {
//         if (res.ok) {
//           cogoToast.success(
//             "Instukcja zmiany hasła została wysłana na podanego maila"
//           );
//           return res.json();
//         } else {
//           cogoToast.error("Podany email nie istnieje w naszej bazie danych");
//         }
//       })
//       .catch((error) => {
//         console.error("error: ", error);
//       });
//   };

  const onLogin = () => {
    if (email === "" || pass === "") {
      cogoToast.error("please fill form");
    } else {
      fetch(`${BACKEND_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: pass,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode === 200) {
            cogoToast.success("Zalogowany");
            sessionStorage.setItem("user", JSON.stringify(response));
            navigate("/");
            window.location.reload()
          } else if (response[0].statusCode === 400) {
            cogoToast.error("Nieprawidłowe hasło lub email");
          } else if (response[0].statusCode === 500) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          } else if (response[0].statusCode === 404) {
            cogoToast.error(
              "Nie znaleziono użytkownika, prosze spróbować jeszcze raz"
            );
          }
        })
        .catch((error) => {
          console.error("error: ", error);
        });
    }
  };
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Logowanie</p>
          </div>
          <div className="login-user-placeholder">
            <input
              name="login"
              type="email"
              placeholder="E-mail"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <div className="login-password-placeholder">
            <input
              name="password"
              type="password"
              placeholder="Hasło"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <Link style={{marginTop:'10px'}} to="/forgotPassword"><span style={{color:"black"}}>Zapomniałem hasła</span></Link>
          <button onClick={onLogin} className="login-user-button">
            Zaloguj się
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default LoginUser;
